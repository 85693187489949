@import "./theme/common/light/common";
@import "./theme/variables";
@import "./theme/vertical-wrapper";
@import "./theme/sidebar";
@import "./theme/navbar";
@import "./theme/layouts";
@import "./theme/settings-panel";
@import "./theme/bases";

body > iframe {
  display: none;
}